import React from 'react'
import FullCalendar from '@fullcalendar/react'
import UserSelector from '../userSelector'

import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { CalendarUserInfo, reservedListRes, RouterProp, showReserveData } from '../data'
import { getStylists } from '../logic/api'
import http from '../api/axiosclient'
import {useSetTitle} from '../customHooks/hooks'

const ReservedListCalendar: React.FC<RouterProp> = props => {
    const [events, setEvents] = React.useState<showReserveData[]>([])
    const [users, setUsers] = React.useState<Array<CalendarUserInfo>>([])
    const [id, setID] = React.useState('')
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setID(event.target.value as string)
    }
    useSetTitle("予約カレンダー", props)

    React.useEffect(() => {
        const f = async () => {
            try {
                const sls = await getStylists("stylists/", "")
                if (sls) {
                    setUsers(sls)
                    return true
                }
            } catch (err) {
                alert(err)
            }
        }
        f()
    }, [])

    React.useEffect(() => {
        const f = async () => {
            try {
                const r = await http.get<reservedListRes>(`reserves/stylists/${id}`)
                setEvents(r.data.reservedList.map((v, ind) => {
                    const t = `${v.userName}${v.menus ? v.menus.join('') : ''}${v.phone ? ` tel:${v.phone}` : ''}`
                    const c = v.checked ? 'gray' : '#3788d8'
                    const ck = (v.checked === undefined || v.checked === false ) ? false : true
                    return { id: `${ind}`, title: t, start: v.start, color: c, extendedProps: {dbID: v.id, checked: ck}}
                }))
            } catch (err) {
                alert(err)
            }
        }
        if (id) {
            f()
        }
    }, [id])

    return (
        <div>
            <UserSelector
                userID={id}
                handleChange={handleChange}
                displayLabel="予約リスト"
                users={users}
            ></UserSelector>
            <FullCalendar
                plugins={[listPlugin, interactionPlugin]}
                initialView='listWeek'
                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'today',
                }}
                locale='ja'
                titleFormat={{
                    month: 'short',
                    day: 'numeric'
                }}
                locales={allLocales}
                events={events}
                contentHeight='auto'
                eventClick={
                    (ev) => {
                        const ind = Number(ev.event.id)
                        const newevents = [...events]
                        newevents[ind].extendedProps.checked=true
                        newevents[ind].color='gray'
                        setEvents(newevents)
                        console.log(ev.event.extendedProps.dbID, id)
                        const f = async () => {
                            try{
                                await http.put(`reserves/stylists/${id}/reservedList/${ev.event.extendedProps.dbID}`)
                            } catch(err) {
                                alert(err)
                            }
                        }
                        f()
                    }
                }
            ></FullCalendar>
        </div>
    )
}

export default ReservedListCalendar