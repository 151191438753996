import React from 'react'
import {TextField, Grid, Button, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {AuthLogin} from './cloud/gcp/login'
import { useHistory } from 'react-router';
import {RouterProp} from './data'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            width: '75%',
        },
        textleft: {
            textAlign: 'left',
        }
    }),
);

const UserLogin: React.FC<RouterProp> = (props) => {
    const navHistory = useHistory()
    const classes = useStyles()
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [isErr, setErr] = React.useState(false)
    React.useEffect(()=>{
        props.handleTitle("ログイン")
    })
    const loginHandle = React.useCallback(async () => {
        try {
            await AuthLogin(email, password)
            navHistory.push("/topmenue")
        } catch (err) {
            setErr(true)
            console.log(err)
        }
    }, [email, password, navHistory])
    return (
        <Grid container justifyContent="space-around" spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={email}
                    error={email.length === 0}
                    helperText={"emailを入力して下さい"}
                    id="auth-email"
                    label="email address"
                    className={classes.field}
                    onChange={(ev) => setEmail(ev?.target.value)}
                ></TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={password}
                    error={password.length === 0}
                    helperText={"パスワードを入力して下さい"}
                    id="auth-password"
                    label="password"
                    className={classes.field}
                    onChange={(ev) => setPassword(ev?.target.value)}
                ></TextField>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={loginHandle} color="primary">ログイン</Button>
            </Grid>
            <Grid item xs={12}>
                {isErr && <Typography color="secondary">emailもしくはパスワードが異なります</Typography>}
            </Grid>
        </Grid>
    )
}

export default UserLogin