import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {AuthLogout, AuthReload} from '../cloud/gcp/login'

const url = process.env.REACT_APP_URL

const config: AxiosRequestConfig = {
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
    }

}

const defaultApiClient = axios.create(config)

defaultApiClient.interceptors.request.use(
    async (req) => {
        let jwt = localStorage.getItem("jwt")

        if (!jwt) {
            console.log("logout")
            await AuthLogout()
            window.location.replace("/login")
        }
        req.headers = {...req.headers,
            "Authorization": `Bearer ${jwt}`
        }
        console.log("req")
        return req
    }
)

let isRetry = true
defaultApiClient.interceptors.response.use(
    async (res) => {
        isRetry = true
        return res
    },
    async (err: AxiosError) => {
        if (err.response?.status === 401 && isRetry) {
            console.log("auth fail")
            isRetry = false
            const jwt = await AuthReload()
            if(jwt) {
                console.log("retry")
            }
            return defaultApiClient.request(err.config)
        }
        return Promise.reject(err);
    }
)

export default defaultApiClient