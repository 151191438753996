import React from 'react'
import {SelectUserProp} from './data'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '90%',
    },
    textl: {
        textAlign: 'left',
    },
    textleft: {
        margin: theme.spacing(2),
        textAlign: 'left',
    },
  }),
);

const UserSelector: React.FC<SelectUserProp> = (prop) => {
    const classes = useStyles()
    return (
        <>
            <Grid container justifyContent='flex-start'>
                <Grid item xs={7}>
                    <Typography className={classes.textleft}>{prop.displayLabel}</Typography>
                </Grid>
                <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                    <InputLabel>対象者</InputLabel>
                    <Select
                        value={prop.userID}
                        onChange={prop.handleChange}
                    >
                        {prop.users.map((val) => {
                            return (
                                <MenuItem key={val.id} value={val.id} className={classes.textl}>{val.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                </Grid>
            </Grid>
        </>

    )
}

export default UserSelector