import React from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography} from '@material-ui/core'
import { ColorTypes, HolidayEventItem } from './data'
import http from './api/axiosclient'

const BaseCalendar: React.FC<{ events: Array<HolidayEventItem>, userID: string }> = props => {
    const [targetDay, setTargetDay] = React.useState("")
    const [open, setOpen] = React.useState(false)
    const [events, setEvents] = React.useState<Array<HolidayEventItem>>([])

    React.useEffect(
        () => {
            setEvents(props.events)
        }
        , [props])
    const isEvent = React.useCallback((): boolean => {
        return !!events.find(event => event.start === targetDay)
    }, [events, targetDay])

    const deleteAction = React.useCallback(() => {
        const ev = events.find(ev => ev.start === targetDay)
        const id = ev?.id
        const f = async (id: string) => {
            try {
                await http.delete(`stylists/${props.userID}/holidays/${id}`)
                setEvents(events.filter(ev => ev.start !== targetDay));
            } catch (err) {
                alert(err)
            }
            setOpen(false)
        }
        if (id) {
            f(id)
        }
    }, [targetDay, events, props])

    const addAction = React.useCallback(() => {
        const f = async () => {
            try {
                const data = await http.post<{ id: string }>(
                    'holidays',
                    {
                        start: `${targetDay}T00:00:00+09:00`,
                        userID: props.userID,
                    }
                )
                setEvents([...events, {
                    id: data.data.id,
                    title: '休日',
                    start: targetDay,
                    display: 'background',
                    backgroundColor: props.userID === 'holiday' ? ColorTypes.pinkAlmond : ColorTypes.normalGray,
                }]);
            } catch (err) {
                alert(err)
            }
            setOpen(false)
        }
        if (props.userID !== '') {
            f()
        }
    }, [events, targetDay, props.userID])

    return (
        <div>
            <FullCalendar
                selectable={true}
                events={[...events]}
                plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                ]}
                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'today',
                }}
                titleFormat={{
                    year: 'numeric',
                    month: 'short',
                }}
                locale="ja"
                locales={allLocales}
                weekends={true}
                dateClick={(info) => {
                    if (props.userID) {
                        if (props.userID !== 'holiday') {
                            const ev = events.find(ev => ev.start === info.dateStr)
                            if (ev?.backgroundColor !== ColorTypes.pinkAlmond) {
                                setTargetDay(info.dateStr)
                                setOpen(true)
                            }
                        } else {
                            setTargetDay(info.dateStr)
                            setOpen(true)
                        }
                    }
                }}
                contentHeight='auto'
            >
            </FullCalendar>
            <Dialog open={open}>
                <DialogTitle>休日の編集</DialogTitle>
                <DialogContent><Typography>{targetDay}</Typography></DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>キャンセル</Button>
                    {isEvent()
                        ? <Button onClick={deleteAction} variant="contained" color="secondary">削除</Button>
                        : <Button onClick={addAction} variant="contained" color="primary">登録</Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BaseCalendar