import React from 'react'
import TopMenue from './TopMenue'
import MyCalendar from './menue/calendar'
import HolidayCalendar from './menue/holidayCalendar';
import ShiftCalendar from './menue/shiftCalendar';
import ReservedListCalendar from './menue/reservedList';
import Login from './login'
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import './App.css';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'
import { AuthLogout } from './cloud/gcp/login';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar,
    root: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(2),
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },

  })
);

function App() {
  const [title, setTitle] = React.useState("")
  const handleTitle = React.useCallback((title: string) => {
    setTitle(title)
  }, [setTitle])
  React.useEffect(() => {
    document.title = "美容室ueda管理者用"
  }, [])

  const classes = useStyles();
  return (
    <div className="App">
      <BrowserRouter>
        <AppBar position='fixed'>
          <Toolbar>
            <IconButton disabled={window.location.pathname === '/'} edge="start" color="inherit" onClick={() => { window.history.back() }}>
              <ChevronLeft></ChevronLeft><Typography variant='body1'>戻る</Typography>
            </IconButton>
            <Typography variant='body1' className={classes.title}>{title}</Typography>
            <IconButton edge="start" color="inherit"  onClick={() => {
              AuthLogout()
              window.location.replace("/login")
            }}>
              <Typography variant='body1'>ログアウト</Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.offset}></div>
        <Switch>
          <Route path="/login">
            <Login handleTitle={handleTitle}></Login>
          </Route>
          <Route path="/reaserved">
            <ReservedListCalendar handleTitle={handleTitle}></ReservedListCalendar>
          </Route>
          <Route path="/topmenue">
            <TopMenue handleTitle={handleTitle}></TopMenue>
          </Route>
          <Route path="/shift">
            <ShiftCalendar handleTitle={handleTitle}></ShiftCalendar>
          </Route>
          <Route path="/holiday">
            <HolidayCalendar handleTitle={handleTitle}></HolidayCalendar> 
          </Route>
          <Route path="/reserve">
            <MyCalendar handleTitle={handleTitle}></MyCalendar>
          </Route>
          <Route path="*">
            <TopMenue handleTitle={handleTitle}></TopMenue>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
