import React from "react"

export type RouterProp = {
    handleTitle: (title: string) => void
}

export type SelectUserProp = {
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
    userID: string
    displayLabel: string
    users: Array<CalendarUserInfo>
}

export interface ReservedEventItem {
    title: string
    start: string
    end: string
}

export type CalendarUserInfo = {
    name: string
    id: string
}

export type CalendarUsersRes = {
    stylists: Array<CalendarUserInfo>
}

export type TestApiRes = {
    id: string;
    msg: string;
}

export interface EventItem {
    id: string
    count?: number
    countMax?: number
    title: "　◎" | "" | '1' | '2' | '休日'
    start: string
    end: string
    backgroundColor: "#0062A0" | "#808080" | '#CC8080'
    meta?:
    {
        name?: string
        phone?: string
        shortcut?: string
    }
}

export const ColorTypes = {
    normalGray: "#202020",
    pinkAlmond: "#e3acae",
} as const

type Unpacked<T> = T extends { [K in keyof T]: infer U } ? U : never
export interface HolidayEventItem {
    title: '休日'
    id: string
    start: string
    display: 'background'
    backgroundColor: Unpacked<typeof ColorTypes>
}

export type ReservedRes = {
    reservedList: Array<reservedDataRes> | undefined
}

export interface YearDataRes {
    yearDays: {
        [year: number]: Array<{
            id: string,
            yearOfDay: number,
        }>
    }
}

type reservedDataRes = {
    id: string
    reservedCountMax: number,
    reservedCount: number,
    start: string,
    end: string
    userName?: string
    phone?: string
    menus?: string[]
}

export const ClanderType = {
    shift: 'ClanderTypeShift',
    holiday: 'ClanderTypeHoliday'
} as const

export type reservedListRes = {
    reservedList: reservedData[]
}

export type reservedData = {
    start: string
    userName: string
    phone: string
    menus?: string[]
    checked?: boolean
    id: string
}

export type showReserveData = {
    id: string
    title: string
    start: string
    color: '#3788d8' | 'gray'
    extendedProps: {
        checked: boolean
        dbID: string
    }
}