import React from 'react'
import BaseCalendar from '../baseCalendar';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { HolidayEventItem, RouterProp } from '../data';
import { getHoliday } from '../logic/api';
import { useSetTitle } from '../customHooks/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textleft: {
        margin: theme.spacing(2),
        textAlign: 'left',
    },
  }),
);

const HolidayCalendar: React.FC<RouterProp> = (props) => {
  const [events, setEvents] = React.useState<Array<HolidayEventItem>>([])
  const classes = useStyles();

  useSetTitle("休日カレンダー", props)

  React.useEffect(() => {
    const f = async () => {
      const evs = await getHoliday("holidays/", "holiday")
      if (evs) {
        setEvents(evs)
      }
    }
    f()
  }, [])

  return (
    <>
      <Typography className={classes.textleft}>店舗休日編集用カレンダー</Typography>
      <BaseCalendar events={events} userID="holiday"></BaseCalendar>
    </>
  )
}

export default HolidayCalendar