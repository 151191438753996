import React from 'react';
// import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"
// import '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
// import resourceTimeLinePlugin from "@fullcalendar/resource-timeline"
import allLocales from "@fullcalendar/core/locales-all"

import { HolidayEventItem, ReservedRes, EventItem, CalendarUserInfo, RouterProp } from '../data'
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import UserSelector from '../userSelector';

import creater from "../logic/event"
import moment from 'moment'
import { getStylists, getHoliday } from '../logic/api'
import http from '../api/axiosclient'
import { useSetTitle } from '../customHooks/hooks';

const MyCalendar: React.FC<RouterProp> = props => {
    const [id, setID] = React.useState('')
    const [target, setTarget] = React.useState<EventItem | undefined>()
    const [events, setEvents] = React.useState<Array<EventItem>>()
    const [userName, setUserName] = React.useState("staff")
    const [open, setOpen] = React.useState(false)
    const [users, setUsers] = React.useState<Array<CalendarUserInfo>>([])

    useSetTitle("予約編集", props)

    React.useEffect(() => {
        const f = async () => {
            const sls = await getStylists("stylists/", "")
            if (sls) {
                setUsers(sls)
            }
        }
        f()
    }, [])

    const loadUserEvent = async () => {
        const getEventList = async () => {
            let rs: Array<EventItem> = []
            const res = await http.get<ReservedRes>(
                `reserves/stylists/${id}`
            )
            if (res.data.reservedList) {
                for (const r of res.data.reservedList) {
                    rs = [...rs, {
                        id: r.id,
                        title: "",
                        start: r.start,
                        end: r.end,
                        backgroundColor: "#808080",
                        meta: {
                            name: r?.userName,
                            phone: r?.phone,
                            shortcut: r?.menus?.join('')
                        }, countMax: r.reservedCountMax, count: r.reservedCount,
                    }]
                }
            }
            let ev: HolidayEventItem[] = []
            const hevs = await getHoliday("holidays/", 'holiday')
            let sevs = await getHoliday("holidays/", id)
            let rtnEventList: EventItem[] = []
            if (sevs && hevs) {
                sevs = sevs.filter(sev => !hevs.find((hev => hev.start === sev.start)))
                ev = [...sevs, ...hevs]

                ev.sort((a, b) => {
                    if (a.start < b.start) return -1
                    if (a.start < b.start) return 1
                    return 0
                })
                rtnEventList = creater(id, rs, ev)
            } else {
                rtnEventList = creater(id, rs)
            }
            return rtnEventList
        }
        try {
            let evnetList: EventItem[] = await getEventList()
            setEvents(evnetList)
        } catch (err) {
            alert(err)
        }
    }
    React.useEffect(() => {
        if (id) {
            loadUserEvent()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const isReserved = React.useCallback(() => {
        return target?.id === ""
    }, [target])


    const handleChangeID = (event: React.ChangeEvent<{ value: unknown }>) => {
        setID(event.target.value as string)
    }

    const addAction = React.useCallback(() => {
        const f = async () => {
            try {
                const { data } = await http.post<string>('reserves',
                    {
                        userName: userName,
                        stylistID: id,
                        start: target?.start,
                    })
                if (events && target) {
                    var s = moment(target.start)
                    var addEventList: Array<EventItem> = []
                    var tgtEventList = events.filter(ev => (ev.start === target.start))
                    if (id === "1" && s.hour() === 10 && s.minute() === 0 &&
                        tgtEventList.length === 1
                    ) {
                        addEventList = [...addEventList, {
                            id: data,
                            title: "",
                            start: target.start,
                            end: target.end,
                            backgroundColor: "#808080",
                            meta: { name: userName }
                        },
                        {
                            id: "",
                            title: "",
                            start: target.start,
                            end: target.end,
                            backgroundColor: "#0062A0"
                        }]
                    } else if (tgtEventList.length === 2) {
                        for (const e of tgtEventList) {
                            if (e.id) {
                                addEventList = [...addEventList, {
                                    id: e.id,
                                    title: "",
                                    start: target.start,
                                    end: target.end,
                                    backgroundColor: "#808080",
                                    meta: { name: e.meta?.name }
                                }]
                            } else {
                                addEventList = [...addEventList, {
                                    id: data,
                                    title: "",
                                    start: target.start,
                                    end: target.end,
                                    backgroundColor: "#808080",
                                    meta: { name: userName }
                                }]
                            }
                        }
                    } else {
                        addEventList = [
                            {
                                id: data,
                                title: "",
                                start: target.start,
                                end: target.end,
                                backgroundColor: "#808080",
                                meta: { name: userName }
                            }]
                    }
                    setEvents([...events.filter(ev => (ev.start !== target.start)),
                    ...addEventList])
                }
            } catch (err:any) {
                if (err.response && err.response.status === 500 && err.response.data.indexOf("booking overlap") >= 0) {
                    alert(`既に予約が埋まっております。\r\n再度、空いている時間をご指定下さい。`)
                    loadUserEvent();
                } else {
                    alert(err)
                }
            } finally {
                setOpen(false)
            }
        }
        f()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, target, events, userName])

    const deleteAction = React.useCallback(() => {
        const f = async () => {
            try {
                await http.delete(`reserves/stylists/${id}/reservedList/${target?.id}`)
                if (events && target) {
                    var tgtEventList = events.filter(ev => (ev.start === target.start && ev.id !== ""))
                    if (tgtEventList.length === 2) {
                        setEvents([...events.filter(ev => (ev.id !== target.id)),
                        {
                            id: "",
                            title: "",
                            start: target.start,
                            end: target.end,
                            backgroundColor: "#0062A0"
                        }])
                    } else {
                        setEvents([...events.filter(ev => (ev.start !== target.start)),
                        {
                            id: "",
                            title: "　◎",
                            start: target.start,
                            end: target.end,
                            backgroundColor: "#0062A0"
                        }])
                    }
                }
            } catch (err) {
                alert(err)
            } finally {
                setOpen(false)
            }
        }
        if (target && target.id !== "") {
            f()
        }
    }, [id, target, events])

    return (
        <div>
            <UserSelector
                userID={id}
                handleChange={handleChangeID}
                displayLabel="予約編集カレンダー"
                users={users}
            ></UserSelector>
            <FullCalendar
                // plugins={[dayGridPlugin, interactionPlugin]}
                hiddenDays={[]}
                eventOrder="order"
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                ]}

                weekends={true}
                initialView="timeGridWeek"
                allDaySlot={false}
                events={events}
                displayEventTime={false}

                eventClick={(arg) => {
                    const ev = arg.event
                    if (ev.title === '休日') {
                        return
                    }
                    if (ev.id === "") {
                        setTarget({
                            id: ev.id,
                            title: "　◎",
                            start: ev.startStr,
                            end: ev.endStr,
                            backgroundColor: "#0062A0",
                        })
                    } else {
                        const m = events?.filter(e => e.id === ev.id)[0]
                        setTarget({
                            id: ev.id,
                            title: "",
                            start: ev.startStr,
                            end: ev.endStr,
                            backgroundColor: "#808080",
                            meta: {
                                name: m?.meta?.name,
                                phone: m?.meta?.phone,
                                shortcut: m?.meta?.shortcut,
                            }
                        })
                    }
                    setOpen(true)
                }}

                dayHeaderFormat={{
                    weekday: 'short',
                    day: 'numeric',
                }}

                locale="ja" // 日本語
                slotDuration={"00:30:00"}      // 表示する時間軸の最小値
                slotLabelInterval={"00:30:00"}

                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                }}

                contentHeight="auto"

                selectable={false} // 日付選択可能

                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'today',
                }}

                titleFormat={{
                    year: 'numeric',
                    month: 'short',
                }}

                scrollTimeReset={false}

                slotMinTime={"10:00:00"}
                slotMaxTime={"18:30:00"}

                locales={allLocales}
            ></FullCalendar>
            <Dialog open={open}>
                <DialogTitle>予約変更</DialogTitle>
                <DialogContent>
                    <Typography>{moment(target?.start).format('YYYY-MM-DD HH:mm')}</Typography>
                    <Typography>{target?.meta?.name}</Typography>
                    <Typography>{target?.meta?.shortcut}</Typography>
                    <Typography>{target?.meta?.phone}</Typography>
                    {isReserved() && <TextField
                        value={userName}
                        helperText={'予約一覧に表示するユーザー名を入力して下さい'}
                        label='ユーザー名'
                        onChange={(ev) => setUserName(ev.target.value)}
                    ></TextField>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>キャンセル</Button>
                    {
                        isReserved()
                            ? <Button onClick={addAction} variant="contained">登録</Button>
                            : <Button onClick={deleteAction} variant="contained">削除</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MyCalendar