import { ActionTypes } from "./actionType";
import {AuthActionTypes, AuthState} from "./types"

const initState: AuthState = {
    auth: {
        ID: undefined,
        JWT: "",
        Name: "",
    }
}

export const authReducer = (state = initState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case ActionTypes.setUserInfo:
            const newAuth: AuthState = {
                ...state,
                auth: {
                    ID: action.payload.ID,
                    JWT: action.payload.JWT,
                    Name: action.payload.Name
                }
            }
            return newAuth
        default:
            return state
    }
}