export const firebaseConfig = {
  apiKey: "AIzaSyCtK2JR0GcjC27s9RwYPZTiX9ZK3Gbm69E",
  authDomain: "saron-system.firebaseapp.com",
  projectId: "saron-system",
  storageBucket: "saron-system.appspot.com",
  messagingSenderId: "283274714078",
  appId: "1:283274714078:web:795f7d9613ee22cacb1b4f"
};


