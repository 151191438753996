import React from 'react'
import {List, ListItem, ListItemText, ListItemIcon, Divider} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';
import {useHistory} from 'react-router-dom'
import { RouterProp } from './data';

const TopMenue: React.FC<RouterProp> = props => {
    const [disable, setDisable] = React.useState(false);
    const navHistory = useHistory();
    React.useEffect(() => {
        if (!localStorage.getItem("jwt")) {
            window.location.replace("/login")
        }
        props.handleTitle("トップメニュー")
    }, [props]) 
    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        path: string,
    ) => {
        setDisable(true)
        navHistory.push(path)
    }
    return (
        <List component="nav">
            <ListItem button onClick={(event) => handleClick(event, '/reaserved')} disabled={disable} key={"menue-0"}>
                <ListItemText primary="予約一覧"></ListItemText>
                <ListItemIcon>
                    <ChevronRight></ChevronRight>
                </ListItemIcon>
            </ListItem>
            <Divider></Divider>
            <ListItem button onClick={(event) => handleClick(event, '/holiday')} disabled={disable} key={"menue-1"}>
                <ListItemText primary="店舗定休日設定"></ListItemText>
                <ListItemIcon>
                    <ChevronRight></ChevronRight>
                </ListItemIcon>
            </ListItem>
            <Divider></Divider>
            <ListItem button onClick={(event) => handleClick(event, '/shift')} disabled={disable} key={"menue-2"}>
                <ListItemText primary="スタイリスト・シフト"></ListItemText>
                <ListItemIcon>
                    <ChevronRight></ChevronRight>
                </ListItemIcon>
            </ListItem>
            <Divider></Divider>
            <ListItem button onClick={(event) => handleClick(event, "/reserve")} disabled={disable} key={"menue-3"}>
                <ListItemText primary="予約情報の編集"></ListItemText>
                <ListItemIcon>
                    <ChevronRight></ChevronRight>
                </ListItemIcon>
            </ListItem>
        </List>
    )
}

export default TopMenue