import moment from 'moment-timezone'
import { EventItem, HolidayEventItem } from "../data"

const num = (18 - 10) * 2 + 1
const ds = [...Array(65)].map((_, i) => i)
const ms = [...Array(num)].map((_, i) => i * 30)

const eventCreater = (id: string, reserved: Array<EventItem> = [], holiday: HolidayEventItem[] = []): Array<EventItem> => {
    const n = moment()
    const base = moment.utc([n.year(), n.month(), n.date(), 1]).tz('Asia/Tokyo')
    const end = moment(base).add(2, 'M').add(1, 'd')
    let ev: Array<EventItem> = []

    const l = reserved.length
    let pos = 0
    for (const d of ds) {
        const b = moment(base).add(d, 'd')
        // 一回一回ループを回さなくても良いようにソートすることを考える

        /*
            const days = Object.entries(dic).map(([year, days]) => days.map((day) => 
            {
                return moment().year(year).dayOfYear(day)
            }));

            days.reduce((a, c) => [...c, ...a]).sort((a, b) => {
                if (a.isBefore(b)) return -1
                if (a.isAfter(b)) return 1
                else return 0
            });
        */
        let isHoliday = false
        if (holiday.find(h => h.start === b.format('YYYY-MM-DD'))) {
            isHoliday = true
        }
        if (end.isSameOrBefore(b)) {
            break
        }
        for (const m of ms) {
            const s = moment(b).add(m, 'm')
            const e = moment(s).add(30, 'm')
            if (pos < l && reserved[pos].start === s.format()) {
                pos++
                if (id === "1" && s.hour() === 10 && s.minute() === 0 && reserved.filter(x => x.start === s.format()).length === 2) {
                    pos++
                    if (e.isSameOrBefore(n)) {
                        continue
                    }
                    ev = [...ev, {
                        id: reserved[pos - 2].id,
                        title: "",
                        start: s.format(),
                        end: e.format(),
                        backgroundColor: "#808080",
                        meta: { name: reserved[pos - 2]?.meta?.name, phone: reserved[pos - 2]?.meta?.phone, shortcut: reserved[pos - 2]?.meta?.shortcut }
                    }, {
                        id: reserved[pos - 1].id,
                        title: "",
                        start: s.format(),
                        end: e.format(),
                        backgroundColor: "#808080",
                        meta: { name: reserved[pos - 1]?.meta?.name, phone: reserved[pos - 1]?.meta?.phone, shortcut: reserved[pos - 1]?.meta?.shortcut }
                    }]
                } else if (id === "1" && s.hour() === 10 && s.minute() === 0 && reserved.filter(x => x.start === s.format()).length === 1) {
                    if (e.isSameOrBefore(n)) {
                        continue
                    }
                    var r = reserved[pos - 1];
                    ev = [...ev, {
                        id: r.id,
                        title: "",
                        start: s.format(),
                        end: e.format(),
                        backgroundColor: "#808080",
                        meta: { name: r?.meta?.name, phone: r?.meta?.phone, shortcut: r?.meta?.shortcut }
                    }]
                    if (r.count === r.countMax && r.count === 2) {
                        ev = [...ev, {
                            id: r.id,
                            title: "",
                            start: s.format(),
                            end: e.format(),
                            backgroundColor: "#808080",
                            meta: { name: r?.meta?.name, phone: r?.meta?.phone, shortcut: r?.meta?.shortcut }
                        }]
                    } else {
                        ev = [...ev, {
                            id: "",
                            title: "",
                            start: s.format(),
                            end: e.format(),
                            backgroundColor: "#0062A0",
                        }]
                    }
                } else {
                    if (e.isSameOrBefore(n)) {
                        continue
                    }
                    ev = [...ev, {
                        id: reserved[pos - 1].id,
                        title: "",
                        start: s.format(),
                        end: e.format(),
                        backgroundColor: "#808080",
                        meta: { name: reserved[pos - 1]?.meta?.name, phone: reserved[pos - 1]?.meta?.phone, shortcut: reserved[pos - 1]?.meta?.shortcut }
                    }]
                }
            } else {
                if (e.isSameOrBefore(n)) {
                    continue
                }
                if (isHoliday) {
                    ev = [...ev, {
                        id: "",
                        title: "休日",
                        start: s.format(),
                        end: e.format(),
                        backgroundColor: "#CC8080",
                        meta: { name: reserved[pos - 1]?.meta?.name, phone: reserved[pos - 1]?.meta?.phone, shortcut: reserved[pos - 1]?.meta?.shortcut }
                    }]
                } else {
                    ev = [...ev, {
                        id: "",
                        title: "　◎",
                        start: s.format(),
                        end: e.format(),
                        backgroundColor: "#0062A0",
                    }]
                }
            }
        }
    }
    return ev
}

export default eventCreater