import { YearDataRes, HolidayEventItem, CalendarUserInfo, CalendarUsersRes, ColorTypes } from '../data';
import moment from 'moment'
import http from '../api/axiosclient'

export const getHoliday = async (url: string, id: string): Promise<Array<HolidayEventItem> | undefined> => {
    let tmp: Array<HolidayEventItem> = []
    try {
        console.log(id)
        const res = await http.get<YearDataRes>(
            url,
            { params: { id: id } } // query
        )
        const yd = res.data.yearDays
        for (const ys in yd) {
            const y = Number(ys)
            const evs = yd[y].map((d): HolidayEventItem => {
                const start = moment().year(y).dayOfYear(d.yearOfDay)
                return {
                    title: '休日',
                    start: start.format("YYYY-MM-DD"),
                    id: d.id,
                    display: 'background',
                    backgroundColor: id === "holiday" ? ColorTypes.pinkAlmond : ColorTypes.normalGray,
                }
            })
            tmp = [...tmp, ...evs]
        }
        tmp.sort((a, b) => {
            if(a.start < b.start) return -1
            if(a.start < b.start) return 1
            return 0
        })
        return tmp
    } catch (err) {
        alert(err)
    }
}

export const getStylists = async (url: string, jwt: string): Promise<Array<CalendarUserInfo> | undefined> => {
    const res = await http.get<CalendarUsersRes>(url)
    console.log(res.status)
    if(res.status === 401) {
        const res = await http.get<CalendarUsersRes>(url)
        return res.data.stylists
    }
    return res.data?.stylists
}