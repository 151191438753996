import {getAuth, signInWithEmailAndPassword, signOut, } from 'firebase/auth'

const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));

export const AuthLogin = async (email: string, password: string): Promise<string> => {
    const auth = getAuth();
    const usercre = await signInWithEmailAndPassword(auth, email, password)
 
    for (let i = 0; i < 5; i++) {
        const user =  usercre.user;
        if (user === null ) {
            sleep(1000)
            continue
        }
        const token = await user.getIdToken(true);
        localStorage.setItem("jwt", token)
        return token;
    }
    return ""
}

export const AuthReload = async (): Promise<string> => {
    localStorage.removeItem("jwt")
    const auth = getAuth();
    for (let i = 0; i < 5; i++) {
        const user = auth.currentUser
        if (user === null ) {
            sleep(1000)
            continue
        }
        await user.reload()
        const token = await user.getIdToken(true)
        localStorage.setItem("jwt", token)
        return token
    }
    return ""
}

export const AuthLogout = async (): Promise<void> => {
    const auth = getAuth();
    await signOut(auth)
}