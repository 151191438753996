import React from 'react'
import UserSelector from '../userSelector';
import BaseCalendar from "../baseCalendar";
import { HolidayEventItem, CalendarUserInfo, RouterProp } from '../data';
import { getHoliday, getStylists } from '../logic/api';
import { useSetTitle } from '../customHooks/hooks';

const ShiftCalendar: React.FC<RouterProp> = (props) => {
  const [users, setUsers] = React.useState<Array<CalendarUserInfo>>([])
  const [events, setEvents] = React.useState<Array<HolidayEventItem>>([])
  const [id, setID] = React.useState('')
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setID(event.target.value as string)
  }

  useSetTitle("シフトカレンダー", props)

  React.useEffect(() => {

    const f = async () => {
      let ev: HolidayEventItem[] = [] 
      const hevs = await getHoliday("holidays/", 'holiday')
      let sevs = await getHoliday("holidays/", id)

      if (sevs && hevs) {
        sevs = sevs.filter(sev => !hevs.find((hev => hev.start === sev.start)))
        ev = [...sevs, ...hevs]

        ev.sort((a,b) => {
            if(a.start < b.start) return -1
            if(a.start < b.start) return 1
            return 0
        })

        console.log(ev)
        setEvents(ev)
      }
    }

    if (id) {
      f()
    }
  }, [id])

  React.useEffect(() => {
    const f = async () => {
      const sls = await getStylists("stylists/", "")
      if (sls) {
        setUsers(sls)
      }
    }
    f()
  }, [])

  return (
    <>
      <UserSelector
        userID={id}
        handleChange={handleChange}
        displayLabel="シフト編集用カレンダー"
        users={users}
      ></UserSelector>
      <BaseCalendar events={events} userID={id}></BaseCalendar>
    </>
  )
}

export default ShiftCalendar